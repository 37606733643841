define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/json", ["exports", "@ember/component", "@glimmer/component", "discourse/components/modal/fullscreen-code", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _fullscreenCode, _service, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="result-json">
    <div class="result-json-value">{{@ctx.value}}</div>
    <DButton
      class="result-json-button"
      @action={{action "viewJson"}}
      @icon="ellipsis-h"
      @title="explorer.view_json"
    />
  </div>
  */
  {
    "id": "CYU+4LhH",
    "block": "[[[10,0],[14,0,\"result-json\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"result-json-value\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"result-json-button\"]],[[\"@action\",\"@icon\",\"@title\"],[[28,[37,1],[[30,0],\"viewJson\"],null],\"ellipsis-h\",\"explorer.view_json\"]],null],[1,\"\\n\"],[13]],[\"@ctx\"],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/json.hbs",
    "isStrictMode": false
  });
  let Json = _exports.default = (_class = class Json extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
    }
    get parsedJson() {
      try {
        return JSON.parse(this.args.ctx.value);
      } catch {
        return null;
      }
    }
    viewJson() {
      this.modal.show(_fullscreenCode.default, {
        model: {
          code: this.parsedJson ? JSON.stringify(this.parsedJson, null, 2) : this.args.ctx.value,
          codeClasses: ""
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "parsedJson", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "parsedJson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewJson", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "viewJson"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Json);
});